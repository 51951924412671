export const badgeArrowStyles = {
  top: {
    bottom: '-10px',
    left: '10%',
    transform: 'translateX(-50%)',
    borderTop: '10px solid #fff',
  },
  bottom: {
    top: '-10px',
    left: '10%',
    transform: 'translateX(-50%)',
    borderBottom: '10px solid #fff',
  },
  left: {
    right: '-6px',
    top: '10%',
    transform: 'translateY(-50%) rotate(45deg)',
    borderTop: '10px solid #fff',
    borderBottom: '10px solid transparent',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid #fff',
  },
  leftCenter: {
    right: '-6px',
    top: '50%',
    transform: 'translateY(-50%) rotate(45deg)',
    borderTop: '10px solid #fff',
    borderBottom: '10px solid transparent',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid #fff',
  },
  leftBottom: {
    right: '-6px',
    top: '90%',
    transform: 'translateY(-50%) rotate(45deg)',
    borderTop: '10px solid #fff',
    borderBottom: '10px solid transparent',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid #fff',
  },
  secondLeftBottom: {
    right: '-6px',
    top: '70%',
    transform: 'translateY(-50%) rotate(45deg)',
    borderTop: '10px solid #fff',
    borderBottom: '10px solid transparent',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid #fff',
  },
  right: {
    left: '-6px',
    top: '10%',
    transform: 'translateY(-50%) rotate(45deg)',
    borderTop: '10px solid transparent',
    borderBottom: '10px solid #fff',
    borderLeft: '10px solid #fff',
    borderRight: '10px solid transparent',
  },
  rightUpperTop: {
    left: '-6px',
    top: '20%',
    transform: 'translateY(-50%) rotate(45deg)',
    borderTop: '10px solid transparent',
    borderBottom: '10px solid #fff',
    borderLeft: '10px solid #fff',
    borderRight: '10px solid transparent',
  },
};
