import dayjs from 'dayjs';
import { STAGE_STATUSES } from '../../../constants/builder-course.constants';

export const COURSE_MOCK_DATA = [
  {
    academic: {
      hours: 250,
      month: 10,
    },
    author_id: '62975813415bbd8e7c951ac4',
    competencies: {
      advanced_level: {},
      author_updated: {},
      basic_level: {},
      case_question_ids: {},
      created_at: '2023-04-19T08:45:53.094Z',
      expert_level: {},
      is_deleted: false,
      level: {},
      matrix_question_ids: {},
      normal_level: {},
      sphere: {
        block_id: '643faa414ef84a444fe889b8',
        created_at: '2023-04-19T08:45:53.073Z',
        title: 'Автоматизация системы управления технологическими процессами (АСУ ТП)',
        updated_at: '2023-04-19T08:45:53.073Z',
        _id: '643faa414ef84a444fe889c1',
      },
      sphere_id: '643faa414ef84a444fe889c1',
      stage: 'published',
      title: 'Автоматизация системы управления технологическими процессами (АСУ ТП)',
      updated_at: '2024-04-05T06:28:32.611Z',
      _id: '643faa414ef84a444fe889cb',
    },
    created_at: '2023-09-11T11:27:38.855Z',
    desc: '<p>Демоверсия оценочной сессии</p>',
    dictionary_id: '62ac824e1c9a1470a3a384dd',
    files: {},
    has_students: true,
    qualification: {
      value: 'Специалист по разработке web-ресурсов',
      _id: '6579cb1927be5d837f6b4142',
    },
    isActive: true,
    is_it: false,
    model_id: '641beecd9ee6b74c056a5487',
    organization: {
      name: 'Университет талантов',
    },
    organization_id: '6290a96c1a020d776a7a6e3d',
    published_at: '2023-09-11T12:04:07.022Z',
    spheres: {},
    stage: 'published',
    stages: [
      {
        color: '#0071CE',
        disabled: false,
        end_date: dayjs().add(1, 'd').toISOString(),
        interval: `${dayjs().format('DD.MM.YYYY')} - ${dayjs().add(1, 'd').format('DD.MM.YYYY')}`,
        is_procto: false,
        href: 1,
        name: 'Входная оценка',
        notified: false,
        order: 1,
        start_date: dayjs().toISOString(),
        timezone: -3,
        ...STAGE_STATUSES.start,
        _id: '65eefe7fba64ae836d5c0906',
      },
      {
        color: '#73D071',
        disabled: false,
        end_date: dayjs().add(3, 'd').toISOString(),
        interval: `${dayjs().add(2, 'd').format('DD.MM.YYYY')} - ${dayjs().add(3, 'd').format('DD.MM.YYYY')}`,
        is_procto: true,
        href: 1,
        name: 'Итоговая оценка',
        notified: false,
        order: 2,
        start_date: dayjs().add(2, 'd').toISOString(),
        timezone: -3,
        ...STAGE_STATUSES.notPassed,
        _id: '65eefe7fba64ae836d5c0906',
      },
    ],
    testing: [
      {
        level: 'normal_level',
        _id: '643faa414ef84a444fe889cb',
      },
    ],
    title: 'Демонстрационная оценочная сессия',
    updated_at: '2023-09-11T12:08:18.756Z',
    _id: '64fef9aaa7c601569fe13428',
  },
];

export const MODEL_MOCK_DATA
  = {
    common_params: {
      certificates: true,
      count_of_attempts: 3,
      count_of_stages: 3,
      level: 'expert_level',
      max_count_of_testers: 10000,
      name: 'Базовая модель для продаж',
      registration_type: 'link',
    },
    created_at: '2023-03-23T06:16:45.241Z',
    display_results: 'competency_only',
    fields_display: {
      show_academic_hours: true,
      show_academic_months: true,
      show_branch_industry: true,
      show_description: true,
      show_docs: true,
      show_it: true,
      show_qualification_name: true,
    },
    id: '641beecd9ee6b74c056a5487',
    levels: [
      { type: 'basic_level', from: 0, to: 17 },
      { type: 'normal_level', from: 18, to: 43 },
      { type: 'advanced_level', from: 44, to: 79 },
      { type: 'expert_level', from: 80, to: 100 },
    ],
    stage: 'published',
    stages: [
      { color: '#73D071', name: 'Начальный этап', order: 1 },
      { color: '#7D6FE1', name: 'Промежуточный этап', order: 2 },
      { color: '#F7B055', name: 'Финальный этап', order: 3 },
    ],
    test_options: {
      output: 'list',
      test_type: 'competency',
      weight_levels: [
        { level: 'normal_level', weight: 1 },
        { level: 'advanced_level', weight: 2 },
        { level: 'expert_level', weight: 3 },
      ],
      weight_questions: [
        { type: 'one', tag: 'competency_level', count: 2, weight: 1, time_for_question: 1 },
        { type: 'case', tag: 'competency', count: 2, weight: 2, time_for_question: 1 },
        { type: 'plural', tag: 'competency_level', count: 2, weight: 1, time_for_question: 1 },
        { type: 'tree', tag: 'competency', count: 0, weight: 2, time_for_question: 1 },
        { type: 'matrix', tag: 'competency', count: 0, weight: 2, time_for_question: 1 },
      ],
    },
    updated_at: '2024-02-21T07:27:51.434Z',
  };


export const COURSE_TAGS_MOCK_DATA = [
  {
    _id: '643faa414ef84a444fe889cb',
    created_at: '2023-04-19T08:45:53.094Z',
    updated_at: '2024-04-05T06:28:32.611Z',
    stage: 'published',
    title: 'Применяет языки программирования для решения профессиональных задач',
    sphere_id: '643faa414ef84a444fe889c1',
    sphere: {
      _id: '643faa414ef84a444fe889c1',
      block_id: '643faa414ef84a444fe889b8',
      title: 'Автоматизация системы управления технологическими процессами (АСУ ТП)',
      created_at: '2023-04-19T08:45:53.073Z',
      updated_at: '2023-04-19T08:45:53.073Z',
    },
    level: [
      {
        description: 'Компетенция не проявляется',
        title: 'Базовый уровень',
      },
      {
        description: 'Осведомлен о принципах работы и назначении оборудования, его взаимосвязи с другими процессами в Обществе',
        title: 'Нормальный уровень',
      },
      {
        description: 'Использует в работе знания о назначении оборудования и особенностях его эксплуатации, эпизодически прибегая к экспертной консультации',
        title: 'Продвинутый уровень',
      },
      {
        description: 'Использует в работе знание систем и средств автоматизации технологических процессов, координирует деятельность специализированных подразделений и организаций',
        title: 'Экспертный уровень',
      },
    ],
    author_updated: {
      id_auth: '626a912474484349e8317819',
      date: '2023-04-28T13:59:19.349Z',
    },
    is_deleted: false,
  },
];

export const MODAL_MOCK_DATA = [
  {
    charts: [
      {
        competency_1: 0.22222222,
        competency_2: undefined,
        competency_3: undefined,
        grade: {
          competency_1: 'normal_level',
          competency_2: undefined,
          competency_3: undefined,
          targetValue: 'normal_level',
        },
        id: '643faa414ef84a444fe889cb',
        key: 1,
        targetValue: '0.18',
        title: 'Применяет языки программирования для решения профессиональных задач',
      },
    ],
    competencies: [
      {
        expertise: 'Применяет языки программирования для решения профессиональных задач',
        id: '643faa414ef84a444fe889cb',
        stages: [
          {
            color: '#0071CE',
            description: 'Применяет языки программирования для решения профессиональных задач под контролем более опытных специалистов',
            id: '65eefe7fba64ae836d5c0902',
            levelLabel: 'Базовый',
            ...STAGE_STATUSES.notPassed,
            name: 'Входная оценка',
          },
          {
            color: '#73D071',
            description: 'Не пройдено',
            id: '65eefe7fba64ae836d5c0903',
            levelLabel: 'Не пройдено',
            ...STAGE_STATUSES.notPassed,
            name: 'Итоговая оценка',
          },
        ],
      },
    ],
    courseTitle: 'Применяет языки программирования для решения профессиональных задач',
    spheres: [],
    stages: [
      {
        color: '#0071CE',
        dataKey: 1,
        name: 'Входная оценка',
      },
      {
        color: '#73D071',
        dataKey: 2,
        name: 'Итоговая оценка',
      },
    ],
  },
];
