import { QuestionsOutputMode } from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable } from 'mobx';
import { MODEL_MOCK_DATA } from './mock-data';
import { IMockData, QUESTIONS_MOCK_DATA } from './pages/demo-assessment-stage/mock-data';

class DemoAssessment {

  public model = MODEL_MOCK_DATA;
  public onlyQuestions = false;
  public userTest = QUESTIONS_MOCK_DATA;
  public userAnswers = null;

  constructor() {
    makeAutoObservable(this);
  }

  public setByOneOutput = (isByOne = true) => {
    this.model.test_options.output = isByOne ? QuestionsOutputMode.byOne : QuestionsOutputMode.list;
  };

  public enableOnlyQuestions = () => {
    this.onlyQuestions = true;
  };

  public setUserTest = (newTest: IMockData[]) => {
    this.userTest = newTest;
  };

  public setUserAnswers = (newAnswers: IMockData | null) => {
    this.userAnswers = newAnswers;
  };

  // Метод для сброса состояния демоверсии
  public resetDemoState = () => {
    this.model = MODEL_MOCK_DATA;
    this.userTest = QUESTIONS_MOCK_DATA;
    this.userAnswers = null;
    this.onlyQuestions = false;
    this.setByOneOutput(false);
  };

}

export const demoAssessment = new DemoAssessment();
