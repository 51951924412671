import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PopoverContentProps } from '@reactour/tour';
import { Button } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { observer } from 'mobx-react';
import { BrowserRoute } from '../../../routes/browser.routes';
import { demoAssessment } from './demoAssessment';

const disabledActions = [
  '[data-tour="main-page-answer-step-3"]',
  '[data-tour="answer-step-2"]',
  '[data-tour="test-step-11"]',
  '[data-tour="introduction-step-8"]',
  '[data-tour-disabled="main-page-answer-step-1"]',
];

export const TourNavigation: PopoverContentProps['components']['Navigation'] = observer(
  ({ currentStep, steps, setCurrentStep }) => {
    const { setByOneOutput, enableOnlyQuestions, resetDemoState } = demoAssessment;
    const navigate = useNavigate();

    const handleNext = () => {
      setCurrentStep(currentStep + 1);
      steps[currentStep].selector === '[data-tour="test-step-1"]' && setByOneOutput();
      steps[currentStep].selector === '[data-tour="test-step-6"]' && enableOnlyQuestions();
    };

    const handlePrev = () => {
      setCurrentStep(currentStep - 1);
      steps[currentStep].selector === '[data-tour="test-step-2"]' && setByOneOutput(false);
    };

    const handleExitDemo = () => {
      resetDemoState();
      sessionStorage.clear();
      navigate(BrowserRoute.courses);
    };

    useEffect(() => {
      document.documentElement.style.overflowY = 'hidden';

      return () => {
        document.documentElement.style.overflowY = 'auto';
      };
    }, []);

    if (disabledActions.includes(steps[currentStep].selector as string)) {
      return null;
    }

    return (
      <div className="flex flex-col gap-2 mt-5">
        <Button onClick={handleNext} size="sm">
          Далее
        </Button>
        <Button
          style={{
            backgroundColor: '#EFF8FE',
            color: '#0071CE',
          }}
          onClick={handlePrev} disabled={currentStep === 0} size="sm" variantStyle="standard">
          Назад
        </Button>
        <Button size="sm" variant="white" onClick={handleExitDemo}>
          Выйти из демоверсии
        </Button>
      </div>
    );
  },
);
